import React, { FunctionComponent } from 'react';
import { Close } from "../../images/icons/close";
import { RefinementItemInterface, RefinementListItemInterface } from './types';

const CustomListItem: FunctionComponent<RefinementListItemInterface> = ({ item, id, refine }) => {

    const toggle = (item: RefinementItemInterface) => {
        refine(item.value);
    };

    return (
        <li className={`ais-RefinementList-item ${item.isRefined && "ais-RefinementList-item--selected"}`}>
            <label htmlFor={`${item.label}-${id}`} className={`ais-RefinementList-label ${item.isDisabled ? 'is-disabled' : ''}`}>
                <input
                    name={`${item.label}-${id}`}
                    id={`${item.label}-${id}`}
                    type="checkbox"
                    checked={item.isRefined}
                    onChange={() => toggle(item)}
                    disabled={item.isDisabled}
                    hidden={true}
                />
                <span className="ais-RefinementList-labelText">
                    {item.label}
                </span>
                {item.isRefined && <span className="ais-RefinementList-count"><span>(</span><Close /><span>)</span></span>}
                {!item.isRefined && <span className="ais-RefinementList-count">{`(${(item.count)})`}</span>}
            </label>
        </li>
    )
}

export default CustomListItem;