import React, { useContext } from "react";
import { LocalizedContext } from "../../services/LocalizedContextService";

export const Equalizer = () => {
  const { sanityLabels } = useContext(LocalizedContext);
  const { searchLabels } = sanityLabels || {};
  const { filter } = searchLabels || {};
  
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{filter}</title>
      <line x1="1" y1="3" x2="25" y2="3" strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="11" x2="25" y2="11" strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="19" x2="25" y2="19" strokeWidth="2" strokeLinecap="round" />
      <rect x="19" width="3" height="6" rx="1.5" fill="none" />
      <rect x="19" y="16" width="3" height="6" rx="1.5" fill="none" />
      <rect x="4" y="8" width="3" height="6" rx="1.5" fill="none" />
    </svg>
  );
};
