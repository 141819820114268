import React, { FunctionComponent, useState, memo, useEffect } from "react";
import qs from "qs";
import { forOwn } from "lodash";
import { Configure, InstantSearch, createInfiniteHitsSessionStorageCache } from "react-instantsearch-dom";
import { useSiteMetadata } from "../../../hooks/useSiteMetadata";
import { SearchState } from "react-instantsearch-core";
import AlgoliaFiltersModal from "../../AlgoliaFiltersModal";
import AlgoliaInfiniteHitsList from "../../AlgoliaInfiniteHits";
import { useAlgoliaClient } from "../../../utils/hooks";
import { AlgoliaInstantSearchInterface } from "./types";
import { useLocation } from '@reach/router'

const AlgoliaInstantSearch: FunctionComponent<AlgoliaInstantSearchInterface> = ({
  data,
  filtersQuery,
  configureFiltersQuery,
  bestSellerQuery,
  source,
  hitsPerPage,
  isRangePage,
  renderFilters
}) => {
  const [configureFilters, setConfigureFilters] = useState(configureFiltersQuery);
  const staticLabels = { ...data.data.sanityLabels?.searchLabels, ...data.data.sanityLabels?.productLabels };
  const { filtersBlockHeading, popularProductsBlockHeading, filtersBlockCTA } = staticLabels;
  const { algoliaIndex } = useSiteMetadata();
  const algoliaClient = useAlgoliaClient();
  const location = useLocation();

  const isLinePage = () => {
    return source?.toLowerCase() === "linepage";
  };

  const DEBOUNCE_TIME = 400;

  const createURL = (searchState: SearchState) => {
    return `?${qs.stringify(searchState)}`;
  };
  
  const searchStateToUrl = (location: Location, searchState: SearchState) => {
    return searchState ? `${location.pathname}${createURL(searchState)}` : "";
  };
  
  const urlToSearchState = (search: string) => {
    if (qs.parse(search.slice(1))?.configure?.filters) {
      setConfigureFilters(qs.parse(search.slice(1))?.configure?.filters);
    }
    return qs.parse(search.slice(1));
  };
  
  const [debouncedSetState, setDebouncedSetState] = useState<number>();
  const [resultHits, setResultHits] = useState<number>(1);
  const [searchState, setSearchState] = useState<SearchState>(() => {
    return typeof window !== `undefined` && isLinePage()
      ? urlToSearchState(location.search)
      : { pageType: ["productV2"] };
  });


  useEffect(() => {
    setConfigureFilters(configureFiltersQuery);
    setSearchState(urlToSearchState(location.search))
  }, [location])

  const onSearchStateChange = (updatedSearchState: SearchState) => {
    let filters = filtersQuery;
    const { refinementList } = updatedSearchState;

    if (isLinePage()) {
      clearTimeout(debouncedSetState);
      setDebouncedSetState(
        window.setTimeout(() => {
          return history.pushState(updatedSearchState, "", searchStateToUrl(location, updatedSearchState));
        }, DEBOUNCE_TIME)
      );
    }

    for (const key in refinementList) {
      if (Array.isArray(refinementList[key])) {
        Array.isArray(
          refinementList[key].map(arr => {
            filters += ` AND ${key}: ${arr.includes(" ") ? `"${arr}"` : arr}`;
          })
        );
      }
    }
    setSearchState(updatedSearchState);
    // setConfigureFilters(filters);
  };

  const isFilterSelected = () => {
    let selectedFilters = isRangePage || false;
    forOwn(searchState.refinementList || [], function (value) {
      if (Array.isArray(value) && value.length > 0) {
        selectedFilters = true;
      }
    });
    return selectedFilters;
  };

  return (
    <>
      {/* Products with Refinetment list */}
      <InstantSearch
        searchClient={algoliaClient}
        indexName={algoliaIndex}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
      >
        <Configure filters={configureFiltersQuery} hitsPerPage={hitsPerPage} />
        <AlgoliaFiltersModal
          staticLabels={staticLabels}
          filtersBlockHeading={filtersBlockHeading}
          filtersBlockCTA={filtersBlockCTA}
          language={data.pageContext.language}
          renderFilters={renderFilters}
        />
        {
          <div hidden={!isFilterSelected()}>
            <AlgoliaInfiniteHitsList staticLabels={staticLabels} resultHits={resultHits} data={data} />
          </div>
        }
      </InstantSearch>
    </>
  );
};

export default AlgoliaInstantSearch;
