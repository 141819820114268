import React, { FunctionComponent } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    InfiniteHits,
    Stats,
    createInfiniteHitsSessionStorageCache
} from "react-instantsearch-dom";

import * as hitComps from "../HitCompV2";
import { HitCompInterface } from "../HitComp";
import './styles.scss';

export interface AlgoliaInfiniteHitsListInterface {
    resultHits?: any;
    staticLabels?: any;
    blockTitle?: string;
    showStats?: boolean;
    data?: any;
}

const AlgoliaInfiniteHitsList: FunctionComponent<AlgoliaInfiniteHitsListInterface> = props => {
    const { resultHits, staticLabels, blockTitle, showStats = true, data } = props;
    const hitCompData: HitCompInterface = {
        productsLandingPageHeading: data.data.sanityProductLandingPageV2?.heading || data.data.productLinePageV2?.heading,
        productsLandingPageSlug: data.data.sanityProductLandingPageV2?.slug.current || 'products',
        article: staticLabels?.article
    };

    const sessionStorageCache = createInfiniteHitsSessionStorageCache();

    return (
        <Container fluid>
            {resultHits !== 0 ? (
                <Row>
                    <Col sm={12} lg={{ span: 10, offset: 1 }}>
                        <div className="rx-product-listing--wrapper">
                            {blockTitle && <h2 className="rx-product-listing--title">{blockTitle}</h2>}
                            {showStats && <Stats
                                translations={{
                                    stats(nbHits, nbSortedHits, areHitsSorted) {
                                        return areHitsSorted && nbHits !== nbSortedHits
                                            ? `${nbHits.toLocaleString()} ${staticLabels.filterResultsCountLabel || "results"}`
                                            : `${nbHits.toLocaleString()} ${staticLabels.filterResultsCountLabel || "results"}`;
                                    }
                                }}
                            />}
                            <InfiniteHits
                                showPrevious={false}
                                hitComponent={hitComps["HitCompV2"](hitCompData)}
                                cache={sessionStorageCache}
                                translations={{
                                    loadMore: `${staticLabels?.loadMore}`
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            ) : (
                <div className="no-results" />
            )}
        </Container>
    )
}

export default AlgoliaInfiniteHitsList;