import React, { useState } from "react";
import { ClearRefinements, Panel, CurrentRefinements } from "react-instantsearch-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { orderBy, uniqBy } from "lodash";

import Accessibility from "../Accessibility";
import CustomRefinementList from "../AlgoliaCustomRefinementList";
import { Equalizer } from "../../images/icons/equalizer";
import { Close } from "../../images/icons/close";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { useAlgoliaClient } from "../../utils/hooks";
import "./styles.scss";

type RefinementListItem = {
  count?: number;
  isRefined?: boolean;
  isDisabled?: boolean;
  label?: string;
  value?: Array<string>;
};

const AlgoliaFiltersModal = ({ filtersBlockHeading, filtersBlockCTA, staticLabels, language, renderFilters }) => {
  const { algoliaIndex } = useSiteMetadata();
  const algoliaClient = useAlgoliaClient();
  const [advancedSearch, setAdvancedSearch] = useState(renderFilters || false);

  const toggleAdvancedSearch = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setAdvancedSearch(true);
  };

  const closeAdvancedSearch = () => {
    setAdvancedSearch(!setAdvancedSearch);
  };

  // GET ALL FACETS FROM ALGOLIA API
  const [facets, setFacets] = useState(() => {
    const formattedFacets = {};
    const facetsFilters = {
      en: {
        facets: ["gender", "benefit", "format", "strength"]
      },
      es: {
        facets: ["gender", "benefit", "format", "strength"]
      },
      pt: {
        facets: ["gender", "benefit", "format"]
      }
    };
    const index = algoliaClient.initIndex(algoliaIndex);
    index
      .search(``, {
        facetFilters: [[`lang:${language}`]],
        facets: facetsFilters[language].facets
      })
      .then(res => {
        for (const key in res.facets) {
          // skip loop if the property is from prototype

          // eslint-disable-next-line no-prototype-builtins
          if (!res.facets.hasOwnProperty(key)) continue;

          const obj = res.facets[key];
          const tempArray = [];
          for (const prop in obj) {
            // skip loop if the property is from prototype
            // eslint-disable-next-line no-prototype-builtins
            if (!obj.hasOwnProperty(prop)) continue;

            tempArray.push({
              label: prop,
              value: [prop],
              count: obj[prop],
              isRefined: false
            });
          }
          formattedFacets[key] = tempArray;
        }
      });
    return formattedFacets;
  });

  return (
    <>
      <div className={`rx-product--filters`}>
        <div className="rx-product--filters-search">
          <button className="rx-product--filters-btn" onClick={toggleAdvancedSearch}>
            {filtersBlockHeading}
            <Equalizer />
          </button>
          <div className="rx-product--refinements">
            <CurrentRefinements />
            <ClearRefinements
              translations={{
                reset: "Clear All"
              }}
            />
          </div>
        </div>
      </div>
      <div className="rx-product--filters-popup" hidden={!advancedSearch}>
        <Container fluid>
          <Row>
            <Col sm={12} className="filters">
              <button onClick={closeAdvancedSearch} className="rx-product--filters-close">
                <Close />
                <Accessibility location={"Close"} />
              </button>
              <div className={"rx-product--filters-content "}>
                <div className="filter">
                  <Panel header={staticLabels.filtersBlockGender ? staticLabels.filtersBlockGender : "Gender"}>
                    <CustomRefinementList
                      attribute="gender"
                      showMore={false}
                      transformItems={(items: any) => {
                        let missingFacets: any[] = [];
                        if (items && facets["gender"]) {
                          if (items.length < facets["gender"].length) {
                            // push only items which are not avaiable in items
                            items.forEach((item: { label: string }) => {
                              missingFacets = facets["gender"].filter(facet => facet.label !== item.label);
                            });
                            missingFacets.forEach(facet => {
                              facet["isDisabled"] = true;
                              items.push(facet);
                            });
                          }
                        }

                        items = uniqBy(items, "label").map(item => {
                          return {
                            ...item,
                            // @ts-ignore
                            label: staticLabels[item.label] || item.label
                          };
                        });
                        // return orderBy(items, "count", "asc");
                        return orderBy(items, "label", "asc");
                      }}
                    />
                  </Panel>
                  <Panel header={staticLabels.filtersBlockFormat ? staticLabels.filtersBlockFormat : "Format"}>
                    <CustomRefinementList
                      attribute="format"
                      showMore={false}
                      transformItems={(items: Array<RefinementListItem>) => {
                        let missingFacets: any[] = [];
                        if (items && facets["format"]) {
                          if (items.length < facets["format"].length) {
                            // push only items which are not avaiable in items
                            items.forEach(item => {
                              missingFacets = facets["format"].filter(facet => {
                                return facet.label !== item.label;
                              });
                            });
                            missingFacets.forEach(facet => {
                              facet["isDisabled"] = true;
                              items.push(facet);
                            });
                          }
                        }

                        items = uniqBy(items, "label").map(item => ({
                          ...item,
                          // @ts-ignore
                          label: staticLabels[item.label] || item.label
                        }));

                        return orderBy(items, "label", "asc");
                      }}
                    />
                  </Panel>
                  { facets["strength"] && <Panel header={staticLabels.filtersBlockStrength ? staticLabels.filtersBlockStrength : "Protection"}>
                    <CustomRefinementList
                      attribute="strength"
                      showMore={false}
                      transformItems={(items: Array<RefinementListItem>) => {
                        let missingFacets: any[] = [];
                        if (items && facets["strength"]) {
                          if (items.length < facets["strength"].length) {
                            // push only items which are not avaiable in items
                            items.forEach(item => {
                              missingFacets = facets["strength"].filter(facet => facet.label !== item.label);
                            });
                            missingFacets.forEach(facet => {
                              facet["isDisabled"] = true;
                              items.push(facet);
                            });
                          }
                        }
                        items = uniqBy(items, "label").map(item => ({
                          ...item,
                          // @ts-ignore
                          label: staticLabels[item.label] || item.label
                        }));
                        return orderBy(items, "label", "asc");
                      }}
                    />
                  </Panel> }
                  <Panel header={staticLabels.filtersBlockBenefit ? staticLabels.filtersBlockBenefit : "Benefit"}>
                    <CustomRefinementList
                      attribute="benefit"
                      showMore={false}
                      transformItems={(items: Array<RefinementListItem>) => {
                        let missingFacets: any[] = [];
                        if (items && facets["benefit"]) {
                          if (items.length < facets["benefit"].length) {
                            // push only items which are not avaiable in items
                            items.forEach(item => {
                              missingFacets = facets["benefit"].filter(facet => facet.label !== item.label);
                            });
                            missingFacets.forEach(facet => {
                              facet["isDisabled"] = true;
                              items.push(facet);
                            });
                          }
                        }

                        items = uniqBy(items, "label").map(item => ({
                          ...item,
                          // @ts-ignore
                          label: staticLabels[item.label] || item.label
                        }));
                        return orderBy(items, "label", "asc");
                      }}
                    />
                  </Panel>
                </div>
                <div className="filters-content">
                  <ClearRefinements
                    clearsQuery
                    translations={{
                      reset: `${staticLabels?.reset}`
                    }}
                  />
                  <button className="btn-done" type="button" onClick={closeAdvancedSearch}>
                    {filtersBlockCTA}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AlgoliaFiltersModal;
