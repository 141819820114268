/* eslint-disable react/display-name */

import React from "react";
import { Link } from "../Link";
import { Highlight } from "react-instantsearch-dom";
import { useInView } from "react-intersection-observer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";
import { productV2Hit } from "../../types/SearchTypes";
import normalize from "normalize-path";
import Heading from "../Heading";
import ProductRatings from "../ProductRatings";

export interface HitCompV2Interface {
  placeholderImage?: ImageInterface;
  productsLandingPageHeading?: string;
  productsLandingPageSlug?: string;
  article?: string;
}

export const HitCompV2 =
  ({ placeholderImage, productsLandingPageSlug }: HitCompV2Interface) =>
  ({ hit }: { hit: productV2Hit }) => {
    const { slug, title, objectID, image, pageType, parentSection, subBrand, range, upc } = hit;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { sanityId, sanityDataset } = useSiteMetadata();

    const urlBuilder = sanityImageUrlBuilder({
      projectId: sanityId,
      dataset: sanityDataset
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: "5px 0px"
    });

    const getImage = () => {
      return image ? image : placeholderImage;
    };

    const imageAlt = () => {
      return image ? image.alt : "placeholder";
    };

    const parentPath = parentSection?.parentSection
      ? `${parentSection.parentSection.slug.current}/${parentSection.slug.current}`
      : parentSection?.slug?.current;

    const renderSlug = () => {
      const urlPath =
        pageType === "article" && parentSection
          ? `/${parentPath}/${slug.current}/`
          : `/${productsLandingPageSlug}/${slug.current}/`;
      return normalize(urlPath, false);
    };

    const renderImage = () => {
      return (
        pageType !== "article" && (
          <>
            <source
              media={"(min-width: 992px)"}
              srcSet={
                urlBuilder
                  .image(getImage())
                  .auto("format")
                  .quality(80)
                  .width(303)
                  .height(294)
                  .format("webp")
                  .url() as string
              }
            />
            <source
              media={"(min-width: 768px)"}
              srcSet={
                urlBuilder
                  .image(getImage())
                  .auto("format")
                  .quality(80)
                  .width(303)
                  .height(294)
                  .format("webp")
                  .url() as string
              }
            />
            <img
              loading="lazy"
              src={
                urlBuilder
                  .image(getImage())
                  .auto("format")
                  .quality(80)
                  .width(303)
                  .height(294)
                  .format("webp")
                  .url() as string
              }
              alt={imageAlt()}
              width="203"
              height="194"
            />
          </>
        )
      );
    };

    return (
      <Row className="ais-InfiniteHits-item__custom">
        <Col xs="12" lg="7" className="ais-InfiniteHits-item__copy">
          <div ref={ref} data-inview={inView} className="hit-compv2">
            <Link _id={objectID} className="ais-InfiniteHits-item__link" to={renderSlug()} aria-label={title}>
              <figure>{inView ? renderImage() : null}</figure>
              {subBrand && <strong>{subBrand}</strong>}
              {range && (
                <Heading headingLevel="h3" cssClass="ais-InfiniteHits-item_range" text={range.replaceAll("-", " ")} />
              )}
              <Heading headingLevel="h3" cssClass="ais-InfiniteHits-item_title">
                <Highlight attribute="title" hit={hit} tagName="mark" />
              </Heading>
              <ProductRatings upc={upc || ""} productName={title} template="pdp" />
            </Link>
          </div>
        </Col>
      </Row>
    );
  };
