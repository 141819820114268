import React from 'react';
import { nanoid } from 'nanoid'
import { connectRefinementList } from 'react-instantsearch-dom';
import CustomListItem from "./listItem";
import { RefinementItemInterface } from './types';

const CustomRefinementList = connectRefinementList((props) => {
    const { items, refine, filterOptions } = props;

    return (
        <div className="ais-RefinementList options">
            <ul className="ais-RefinementList-list" onClick={ filterOptions ? (e) => {filterOptions(e)} : () => {console.log('')} } >
                {items.map((item: RefinementItemInterface) => {
                    return (<CustomListItem key={nanoid()} id={nanoid()} item={item} refine={refine} />);
                })
                }
            </ul>
        </div>
    );
});

export default CustomRefinementList;
